import { makeAutoObservable } from 'mobx'
import { ProductFeatureResultType, checkFeatureHasFeatureEnableEvent } from './models'
import * as R from 'ramda'

export default class Selection {
  public static emptyThermowellId = '00000000-0000-0000-0000-000000000000'
  // stepHeaders: Array<StepHeaderType>;
  private mainProductId: string
  private steps: ProductStepType[]
  private featureResults: Record<string, ProductFeatureResultType>
  private thermowellPool: Record<string, ProductStepItemType[]>
  private productDisplayGroups: ProductDisplayGroupType[]
  stepsSummary: StepSummaryType[]
  stepChangeEnable: boolean
  currentDisplayGroup: ProductDisplayGroupType | null
  thermowellselectionStaticId: string
  currentStep: number
  stepCount: number
  summaryData: { requestId: string; data: SelectionProductSummary[] } | null
  constructor() {
    /**
     * state
     */
    this.mainProductId = ''
    this.thermowellselectionStaticId = 'thermowellselection'
    this.currentStep = 0
    this.stepCount = 0
    this.steps = []
    this.stepsSummary = []
    this.featureResults = {}
    this.productDisplayGroups = []
    this.stepChangeEnable = true
    this.thermowellPool = {}
    this.currentDisplayGroup = null
    this.summaryData = null
    makeAutoObservable(this)
  }

  reset() {
    this.mainProductId = ''
    this.currentStep = 0
    this.stepCount = 0
    this.steps = []
    this.stepsSummary = []
    this.featureResults = {}
    this.productDisplayGroups = []
    this.stepChangeEnable = true
    this.thermowellPool = {}
    this.currentDisplayGroup = null
    this.summaryData = null
  }

  loadSteps(productId: string, val: Array<ProductStepType>) {
    this.mainProductId = productId
    this.currentStep = 1
    this.stepCount = 1
    this.steps = []
    val.forEach((step) => {
      let newStepObject = { ...step }
      // add thermowell select in thermowell step
      if (step.stepType === 2) {
        const thermowellSelectionFeature = this.buildThermowellSelectionFeature(step)
        if (thermowellSelectionFeature) {
          newStepObject.items.push(thermowellSelectionFeature)
        }
      }
      newStepObject.items.forEach((stepItem) => {
        if (stepItem.feature != null) {
          let featureResult = new ProductFeatureResultType(stepItem)
          this.featureResults[stepItem.featureId] = featureResult
        }
      })
      this.steps.push(newStepObject)
      // set steps count
      if (this.stepCount < step.sequence) {
        this.stepCount = step.sequence
      }
    })
    this.stepCount++
    // build step summary
    this.stepsSummary = this.steps.map((step) => {
      return {
        step: step.sequence,
        name: step.name,
        active: step.sequence === 1,
        type: 1,
        enable: step.sequence <= 2,
      }
    })
    this.stepsSummary.push({
      step: this.steps.length + 1,
      name: '参数汇总',
      active: this.steps.length + 1 === this.currentStep,
      type: 2,
      enable: false,
    })
    this.handleEvents()
  }

  loadDisplayGroups(groups: ProductDisplayGroupType[]) {
    this.productDisplayGroups = groups
    this.setDefaultDisplayGroup()
  }

  get stepHeaders(): Array<StepSummaryType> {
    return this.stepsSummary
  }

  goToStep(targetStep: number): void {
    if (targetStep > this.currentStep) {
      const currentStepComplete = this.saveCurrentStep()
      if (!currentStepComplete) {
        // show error
        console.error('current step not completed')
        return
      } else {
        const summary = this.stepsSummary.find((item) => item.step === this.currentStep + 2)
        if (summary) {
          summary.enable = true
        }
      }
    }

    this.currentStep = targetStep
    for (const summary of this.stepsSummary) {
      summary.active = this.currentStep === summary.step
    }
  }

  get currentStepFeatures() {
    const step = this.steps.find((x) => x.sequence === this.currentStep)
    if (!step) {
      return []
    }
    const result: Array<FeatureInputData> = []
    // .filter(item => this.involvedProductIds.indexOf(item.feature.productId) >= 0)
    step.items.forEach((stepItem) => {
      const data = this.formatFeatureData(stepItem)
      if (data) {
        result.push(data)
      }
    })
    // console.log('render', { ...result })
    return result
  }

  get submitResults() {
    const results = []
    for (const featureId in this.featureResults) {
      if (
        !this.featureResults[featureId].enable ||
        featureId === this.thermowellselectionStaticId
      ) {
        continue
      }
      const feature = this.getFeatureInStep(featureId)
      if (!feature) {
        continue
      }
      results.push({
        ProductId: feature?.productId,
        FeatureId: featureId,
        Used: true,
        SelectedOptionId: this.featureResults[featureId].selectedOption?.id,
        Code: this.featureResults[featureId].code,
      })
    }
    return {
      MainProductId: this.mainProductId,
      Complete: true,
      FeatureResults: results,
    }
  }

  saveCurrentStep(): boolean {
    let allPassed = true
    this.currentStepFeatures
      .filter((x) => x.enable)
      .forEach((item) => {
        const featureReslt = this.featureResults[item.id]
        if (!featureReslt) {
          return
        }
        if (featureReslt.productId === Selection.emptyThermowellId) {
          // not check empty thermowell
          return
        }
        if (featureReslt.code == null) {
          allPassed = false
          featureReslt.validateStatus = 'error'
        } else if (featureReslt.type === 4 && featureReslt.minimum && featureReslt.maximum) {
          const numberValue = this.decodeNumberInputCode(featureReslt)
          if (
            !numberValue ||
            numberValue < featureReslt.minimum ||
            numberValue > featureReslt.maximum
          ) {
            allPassed = false
            featureReslt.validateStatus = 'error'
          }
        } else {
          featureReslt.validateStatus = ''
        }
        this.featureResults[item.id] = { ...featureReslt }
      })
    // debugger;
    // console.log(this.currentStepFeatures)
    return allPassed
  }

  setSelectionValue(featureId: string, optionId: string): void {
    const resultItem = this.featureResults[featureId]
    const feature = this.getFeatureInStep(featureId)
    if (!resultItem || !feature) {
      return
    }
    const option = this.getOptionInFeature(optionId, feature)
    if (!option) {
      return
    }
    resultItem.code = option.code
    resultItem.selectedOption = option
    this.handleEvents()
  }

  getSelectionValue(featureId: string): string | null {
    const resultItem = this.featureResults[featureId]
    if (!resultItem || !resultItem.selectedOption) {
      return null
    }
    return resultItem.selectedOption.id
  }

  setNumberInputValue(featureId: string, value: number): void {
    const resultItem = this.featureResults[featureId]
    const feature = this.getFeatureInStep(featureId)
    if (!resultItem || !feature || !feature.numberRangeDetail) {
      return
    }
    resultItem.code = this.numberToCode(
      value,
      feature.numberRangeDetail.digitNumber,
      feature.numberRangeDetail.includeSign
    )
    // this.featureResults[featureId] = { ...resultItem };
  }

  setTemperaureRangeValue(featureId: string, values: number[]): void {
    const resultItem = this.featureResults[featureId]
    const feature = this.getFeatureInStep(featureId)
    if (!resultItem || !feature || !feature.numberRangeDetail || values.length !== 2) {
      return
    }
    resultItem.code =
      this.numberToCode(
        values[0],
        feature.numberRangeDetail.digitNumber,
        feature.numberRangeDetail.includeSign
      ) +
      this.numberToCode(
        values[1],
        feature.numberRangeDetail.digitNumber,
        feature.numberRangeDetail.includeSign
      )
    // this.featureResults[featureId] = { ...resultItem };
  }

  loadThermowellStepItems(thermowellId: string, stepItems: ProductStepItemType[]) {
    console.log(
      'this.thermowellPool[thermowellId]',
      thermowellId,
      this.thermowellPool[thermowellId]?.map((t) =>
        Object.entries(t).map(([key, value]) => ({ [key]: value }))
      )
    )

    if (!this.thermowellPool[thermowellId]) {
      this.thermowellPool[thermowellId] = [...stepItems]
      stepItems.forEach((stepItem) => {
        if (stepItem.feature != null) {
          let featureResult = new ProductFeatureResultType(stepItem)
          this.featureResults[stepItem.featureId] = featureResult
        }
      })
    }
  }

  switchThermowellFeatures(thermowellId: string): boolean {
    // debugger;
    // this.involvedProductIds = [this.mainProductId, thermowellId];
    const thermowellStep = this.getStepByFeatureId(this.thermowellselectionStaticId)
    if (!thermowellStep) {
      return false
    }
    const currentThermowellStepItems = this.thermowellPool[thermowellId]
    if (R.isNil(currentThermowellStepItems)) {
      return false
    }
    const otherThermowellStepItems = thermowellStep.items.filter(
      (item) =>
        item.feature.productId !== thermowellId &&
        item.featureId !== this.thermowellselectionStaticId
    )
    otherThermowellStepItems.forEach((stepItem) => {
      if (this.featureResults[stepItem.featureId]) {
        delete this.featureResults[stepItem.featureId]
      }
    })
    thermowellStep.items = thermowellStep.items.filter(
      (stepItem) => stepItem.featureId === this.thermowellselectionStaticId
    )
    for (let newStepItem of currentThermowellStepItems) {
      thermowellStep.items.push(newStepItem)
      let featureResult = new ProductFeatureResultType(newStepItem)
      this.featureResults[newStepItem.featureId] = featureResult
    }
    // this.loadActions();
    this.handleEvents()
    return true
  }

  // rules
  // 1.no product events
  // 2.feature.visible means feature used
  // 3.if feature is disabled then no handle other events
  handleEvents(): void {
    console.log('handle events')
    let currentResults = { ...this.featureResults }
    this.handleOptionEvents(currentResults)
    this.handleFeatureEnableEvent(currentResults)
    this.handleNumberRangeEvents(currentResults)
    this.featureResults = { ...currentResults }
    this.handleDisplayGroupEvents()
  }

  setSummaryAndGotoSummaryPage(data: SelectionProductSummary[], requestId: string) {
    this.summaryData = {
      requestId,
      data,
    }
    this.currentStep++
    const summary = this.stepsSummary.find((item) => item.step === this.currentStep)
    if (summary) {
      summary.enable = true
    }

    for (const summary of this.stepsSummary) {
      summary.active = this.currentStep === summary.step
    }
  }

  setStepChangeEnable(enable: boolean) {
    this.stepChangeEnable = enable
  }

  getfeatureData(featureId: string): string | number | number[] | null {
    const data = this.featureResults[featureId]
    if (!data) {
      return null
    }
    if (data.type === 3) {
      // selection
      return data.selectedOption?.id ? data.selectedOption.id : null
    } else if (data.type === 4 && data.code) {
      return this.decodeNumberInputCode(data)
    } else if (data.type === 6 && data.code) {
      return this.decodeTemperatureRangeInputCode(data)
    }
    return null
  }

  decodeNumberInputCode(featureResult: ProductFeatureResultType): number | null {
    const feature = this.getFeatureInStep(featureResult.id)
    if (!feature || !feature.numberRangeDetail || feature.type !== 4 || !featureResult.code) {
      return null
    }
    return this.decodeNumberCode(featureResult.code, feature.numberRangeDetail.includeSign)
  }

  private decodeTemperatureRangeInputCode(
    featureResult: ProductFeatureResultType
  ): number[] | null {
    const feature = this.getFeatureInStep(featureResult.id)
    if (!feature || !feature.numberRangeDetail || feature.type !== 6 || !featureResult.code) {
      return null
    }
    let code = featureResult.code
    let unitLength = feature.numberRangeDetail.digitNumber

    if (feature.numberRangeDetail.includeSign) {
      unitLength++
    }
    const min = this.decodeNumberCode(
      code.substring(0, unitLength),
      feature.numberRangeDetail.includeSign
    )
    const max = this.decodeNumberCode(
      code.substring(unitLength),
      feature.numberRangeDetail.includeSign
    )
    return min != null && max != null ? [min, max] : null
  }

  private decodeNumberCode(code: string, includeSign: boolean): number | null {
    if (includeSign) {
      code = code.replace('M', '-').replace('P', '')
    }
    return parseInt(code)
  }

  private handleOptionEvents(results: Record<string, ProductFeatureResultType>) {
    this.steps.forEach((step) => {
      step.items.forEach((stepItem) => {
        const featureResult = results[stepItem.featureId]
        if (
          featureResult &&
          stepItem.feature &&
          stepItem.feature.options &&
          stepItem.feature.options.length > 0
        ) {
          let supportedOptionIds = stepItem.feature.options.map((option) => option.id)
          stepItem.feature.options.forEach((option) => {
            if (!option.actions || option.actions.length === 0) {
              return
            }
            let monopolizeEvents = option.actions.filter((x) => x.actionType === 2)
            const isMonopolize = this.checkActions(monopolizeEvents)
            let disabledEvents = option.actions.filter((x) => x.actionType === 1)
            const isDisabled = this.checkActions(disabledEvents)
            if (isMonopolize && isDisabled) {
              console.error(
                `feature:${stepItem.feature.name} ${stepItem.feature.id} option:${option.text} ${option.id} disabled and monopolize`
              )
            } else if (isMonopolize) {
              if (supportedOptionIds.indexOf(option.id) < 0) {
                console.error(
                  `feature:${stepItem.feature.name} ${stepItem.feature.id} option:${option.text} ${option.id} should be monopolize but was removed`
                )
              }
              supportedOptionIds = [option.id]
            } else if (isDisabled) {
              if (supportedOptionIds.indexOf(option.id) >= 0) {
                supportedOptionIds.splice(supportedOptionIds.indexOf(option.id), 1)
              }
            }
          })
          featureResult.supportedOptionIds = supportedOptionIds
          if (
            featureResult.selectedOption?.id &&
            supportedOptionIds.indexOf(featureResult.selectedOption?.id) < 0
          ) {
            featureResult.selectedOption = null
            featureResult.code = null
          }
          if (!featureResult.selectedOption && supportedOptionIds.length === 1) {
            featureResult.selectedOption = stepItem.feature.options.find(
              (option) => option.id === supportedOptionIds[0]
            )
            featureResult.code = featureResult.selectedOption?.code || null
          }
        }
      })
    })
  }

  private handleFeatureEnableEvent(results: Record<string, ProductFeatureResultType>) {
    this.steps.forEach((step) => {
      step.items.forEach((stepItem) => {
        // debugger;
        // console.log(stepItem.feature.name, stepItem.feature.id);

        const featureResult = results[stepItem.featureId]
        if (
          featureResult &&
          stepItem.feature &&
          stepItem.feature.actions &&
          checkFeatureHasFeatureEnableEvent(stepItem.feature)
        ) {
          const featureEnabledActions = stepItem.feature.actions.filter((x) => x.actionType === 4)
          // if (stepItem.feature.id === '3a0e537c-f5ca-5b54-b708-74841bc29a50') {
          //   debugger;
          // };
          let enabled = this.checkActions(featureEnabledActions)
          featureResult.enable = enabled
        }
      })
    })
  }

  private handleNumberRangeEvents(results: Record<string, ProductFeatureResultType>) {
    this.steps.forEach((step) => {
      step.items.forEach((stepItem) => {
        const featureResult = results[stepItem.featureId]
        if (
          featureResult &&
          stepItem.feature &&
          stepItem.feature.numberRangeDetail &&
          stepItem.feature.actions &&
          (stepItem.feature.type === 4 || stepItem.feature.type === 6)
        ) {
          let max = stepItem.feature.numberRangeDetail.maximum
          let min = stepItem.feature.numberRangeDetail.minimum
          const actions = stepItem.feature.actions.filter(
            (x) => x.actionType === 6 || x.actionType === 7
          )
          actions.forEach((action) => {
            const matched = this.checkConditions(action.conditions)
            if (matched && action.resultValue && !isNaN(parseFloat(action.resultValue))) {
              if (action.actionType === 6) {
                min = parseInt(action.resultValue)
              } else if (action.actionType === 7) {
                max = parseInt(action.resultValue)
              }
            }
          })
          featureResult.maximum = max
          featureResult.minimum = min
          if (featureResult.type === 4 && featureResult.code) {
            const currentValue = this.decodeNumberInputCode(featureResult)
            if (currentValue != null && (currentValue < min || currentValue > max)) {
              featureResult.code = null
            }
          } else if (featureResult.type === 6 && featureResult.code) {
            let storedValues = this.decodeTemperatureRangeInputCode(featureResult)
            if (storedValues) {
              storedValues = storedValues as number[]
              if (storedValues[0] < min) {
                storedValues[0] = min
              }
              if (storedValues[1] > max) {
                storedValues[1] = max
              }
              featureResult.code =
                this.numberToCode(
                  storedValues[0],
                  stepItem.feature.numberRangeDetail.digitNumber,
                  stepItem.feature.numberRangeDetail.includeSign
                ) +
                this.numberToCode(
                  storedValues[1],
                  stepItem.feature.numberRangeDetail.digitNumber,
                  stepItem.feature.numberRangeDetail.includeSign
                )
            }
          }
        }
      })
    })
  }

  private handleDisplayGroupEvents() {
    if (!this.productDisplayGroups || this.productDisplayGroups.length === 0) {
      return
    }
    for (const group of this.productDisplayGroups) {
      if (group.actions) {
        let enabled = this.checkActions(group.actions)
        if (enabled) {
          this.currentDisplayGroup = group
          return
        }
      }
    }
    if (!this.currentDisplayGroup) {
      this.setDefaultDisplayGroup()
    }
  }

  private setDefaultDisplayGroup() {
    if (!this.productDisplayGroups || this.productDisplayGroups.length === 0) {
      return
    }
    for (const group of this.productDisplayGroups) {
      if (group.defaultGroup) {
        this.currentDisplayGroup = group
        break
      }
    }
    if (!this.currentDisplayGroup) {
      this.currentDisplayGroup = this.productDisplayGroups[0]
    }
  }

  private checkActions(actions: SelectionActionType[]): boolean {
    let result = false
    actions.forEach((action) => {
      result = result || this.checkConditions(action.conditions)
    })
    return result
  }

  private checkConditions(conditions: SelectionActionConditionType[] | null): boolean {
    if (!conditions || conditions.length === 0) {
      return false
    }
    let result = true
    conditions.forEach((condition) => {
      if (!result) {
        return
      }
      if (condition.triggeredByProductId) {
        result =
          condition.triggeredByProductId === this.mainProductId ||
          this.getInvolvedProductIds().indexOf(condition.triggeredByProductId) >= 0
        // const productIds = this.featureResults.filter(result => result.visiable).map(result => {
        //   return result.productId
        // })
      } else if (condition.triggeredByOptionId) {
        let optionSelected = false
        for (let featureId in this.featureResults) {
          const feature = this.featureResults[featureId]
          if (feature.selectedOption?.id === condition.triggeredByOptionId) {
            optionSelected = true
            break
          }
        }
        result = optionSelected
      }
    })
    return result
  }

  private getInvolvedProductIds(): string[] {
    const productIds: string[] = []
    for (let featureId in this.featureResults) {
      const featureResult = this.featureResults[featureId]
      if (featureResult.enable && productIds.indexOf(featureResult.productId) < 0) {
        productIds.push(featureResult.productId)
      }
    }
    return productIds
  }

  private getFeatureInStep(featureId: string): ProductFeatureType | null {
    for (let step of this.steps) {
      for (let item of step.items) {
        if (item.featureId === featureId) {
          return item.feature
        }
      }
    }
    return null
  }

  private getStepByFeatureId(featureId: string): ProductStepType | null {
    for (let step of this.steps) {
      for (let item of step.items) {
        if (item.featureId === featureId) {
          return step
        }
      }
    }
    return null
  }

  private getOptionAndItsFeature(
    optionId: string
  ): [ProductFeatureType, SelectionFeatureOptionType] | null {
    for (const step of this.steps) {
      for (const stepItem of step.items) {
        if (stepItem.feature) {
          const item = this.getOptionInFeature(optionId, stepItem.feature)
          if (item) {
            return [stepItem.feature, item]
          }
        }
      }
    }
    return null
  }

  private getOptionInFeature(
    optionId: string,
    feature: ProductFeatureType
  ): SelectionFeatureOptionType | null {
    if (feature.options) {
      for (const option of feature.options) {
        if (option.id === optionId) {
          return option
        }
      }
    }
    return null
  }

  private numberToCode(num: number, length: number, includeSign: boolean) {
    let strValue: string = (Math.abs(num) / Math.pow(10, length)).toFixed(length).substr(2)
    return (includeSign ? (num < 0 ? 'M' : 'P') : '') + strValue
  }

  private formatFeatureData(stepItem: ProductStepItemType): FeatureInputData | null {
    const featureId = stepItem.featureId
    const feature = stepItem.feature
    const featureResult = this.featureResults[featureId]
    if (!featureResult) {
      return null
    }
    const data: FeatureInputData = { ...featureResult }
    data.name = feature.name
    data.description = feature.description
    data.unit = feature.unit
    if (feature.options && featureResult.supportedOptionIds) {
      data.options = feature.options.filter(
        (option) =>
          featureResult.supportedOptionIds &&
          featureResult.supportedOptionIds.indexOf(option.id) >= 0
      )
    }
    return data
  }

  private buildThermowellSelectionFeature(step: ProductStepType | null) {
    if (!step || !step.supportedThermowells || step.supportedThermowells.length === 0) {
      return null
    } else {
      const thermowellSelectionFeature: ProductFeatureType = {
        id: this.thermowellselectionStaticId,
        productId: this.mainProductId,
        name: '护套选择',
        description: '',
        unit: '',
        type: 3,
        operationType: 1,
        defaultCode: '',
        sequence: 0,
        options: step.supportedThermowells.map((x) => {
          const temp: SelectionFeatureOptionType = {
            id: x.id,
            productFeatureId: '',
            text: x.name,
            code: x.name,
            defaultOption: false,
            defaultHidden: false,
            sequence: 1,
            numberValue: null,
            actions: [],
          }
          return temp
        }),
      }
      const thermowellSelectionElement: ProductStepItemType = {
        stepId: step.id,
        featureId: this.thermowellselectionStaticId,
        sequence: 0,
        operationType: 1,
        feature: thermowellSelectionFeature,
      }
      return thermowellSelectionElement
    }
  }
}
